
	<div class="forminput u-margin-bottom2x">
		<div class="forminput-x-labelrow">
			<label class="forminput-x-label u-margin-bottom1x" for="image_field{{ uniqueIdSuffix }}">{{label}}</label>
			<a
				*ngIf="generateRandom"
				(click)="$event.preventDefault();generateRandomImage.emit()"
				class="forminput-x-helplink"
				href="#">Generate Random</a>
		</div>
		<input type="file"
				accept="image/*"
				name="image_field{{ uniqueIdSuffix }}"
				id="image_field{{ uniqueIdSuffix }}"
				(change)="fileInputChanged($event)"
				class="visuallyhidden"
		/>

		<label class="dropzone"
		       #imageLabel
		       [attr.for]="'image_field' + uniqueIdSuffix" (click)="clearFileInput()"
		       tabindex="0"
		       [class.dropzone-is-dragging]="isDragging"
		       [class.dropzone-is-error]="imageErrorMessage || (control.dirty && !control.valid)"
		>
			<div class="dropzone-x-preview" *ngIf="imageDataUrl">
				<img [src]="imageDataUrl" alt="">
				<p class="u-text-body">
					{{ imageName }}
					<button (click)="imageLabel.click()" type="button" class="u-text-link">Change Image</button>
				</p>
			</div>

			<ng-container *ngIf="!imageDataUrl">
				<svg class="dropzone-x-icon" icon="icon_upload"></svg>
				<p class="dropzone-x-info1">{{ 'backpackPage.dragAndDropOr' | translate }}</p>
				<p class="dropzone-x-info2"><span class="u-text-link">{{ 'backpackPage.browse' | translate }}</span></p>
			</ng-container>

		</label>

		<p class="forminput-x-error" *ngIf="control.dirty && !control.valid">{{ errorMessage }}</p>
	</div>
	