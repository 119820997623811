
		<div class="forminput-x-labelrow">
			<label class="forminput-x-label" [attr.for]="inputName" *ngIf="label || includeLabelAsWrapper">
				{{ label }}  <span *ngIf="optional">(OPTIONAL)</span>
				<span *ngIf="formFieldAside">{{ formFieldAside }}</span>
				<button type="button" *ngIf="isLockedState" (click)="unlock()">(unlock)</button>
			</label>
			<ng-content class="forminput-x-helplink" select="[label-additions]"></ng-content>
		</div>
		<p class="forminput-x-sublabel" *ngIf="sublabel"><span *ngIf="remainingCharactersNum >= 0">{{ remainingCharactersNum }}</span>{{ sublabel }}</p>

		<label class="visuallyhidden" [attr.for]="inputName" *ngIf="ariaLabel">{{ ariaLabel }}</label>
		<div class="forminput-x-inputs">
			<input [type]="fieldType"
			       *ngIf="! multiline"
			       [name]="inputName"
			       [id]="inputId"
			       [formControl]="control"
			       [placeholder]="placeholder || ''"
						 [attr.maxlength] = "maxchar"
						 [attr.max] = "max"
			       (change)="postProcessInput()"
			       (focus)="cacheControlState()"
			       (keypress)="handleKeyPress($event)"
			       (keyup)="handleKeyUp($event)"
			       #textInput
			/>
			<div class="forminput-x-button" *ngIf="inlineButtonText">
				<button class="button button-secondary button-informinput"
						(click)="buttonClicked.emit($event)"
						[disabled-when-requesting]="true"
						type="submit"
				>
					{{inlineButtonText}}
				</button>
			</div>
			<textarea *ngIf="multiline"
			          [name]="inputName"
			          [id]="inputId"
			          [formControl]="control"
			          [attr.maxlength] = "maxchar"
			          [placeholder]="placeholder || ''"
			          (change)="postProcessInput()"
			          (focus)="cacheControlState()"
			          (keypress)="handleKeyPress($event)"
			          (keyup)="handleKeyUp($event)"
			          #textareaInput
			></textarea>
		</div>
		<p class="forminput-x-error" *ngIf="isErrorState">{{ errorMessageForDisplay }}</p>
	