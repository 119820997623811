<dialog aria-labelledby="shareBadgeDialog"
				aria-describedby="dialog1Desc"
				class="dialog dialog-is-active l-dialog"
				role="dialog"
>
<div class="dialog-x-box o-container">

		<!-- Header -->
		<div class="dialog-x-header u-background-light3">
			<h1>{{ options?.title }}</h1>
			<button
				(click)="closeDialog()"
				class="buttonicon buttonicon-link">
				<svg icon="icon_close"></svg>
				<span class="visuallyhidden">Close</span>
			</button>
		</div>

		<!-- Tab Navigation Bar -->
		<nav aria-labelledby="heading-share-options" class="tabbar">
			<button
				[class.tab-is-active]="currentTabId === 'link'"
				(click)="openTab('link')"
				class="tab">
					<span class="visuallyhidden">
						Open the </span> Link
				<span class="visuallyhidden"> tab</span>
			</button>
			<button
				[class.tab-is-active]="currentTabId === 'social'"
				(click)="openTab('social')"
				class="tab">
					<span class="visuallyhidden">
						Open the </span>Social
				<span class="visuallyhidden"> tab</span>
			</button>
			<button
				[class.tab-is-active]="currentTabId === 'embed'"
				(click)="openTab('embed')"
				*ngIf="hasEmbedSupport"
				class="tab">
					<span class="visuallyhidden">
						Open the </span>HTML
				<span class="visuallyhidden"> tab</span>
			</button>
		</nav>

		<!-- Link Tab -->
		<div class="u-responsivepadding-yaxis u-responsivepadding-xaxis" tabindex="-1" id="sharelink" *ngIf="currentTabId == 'link'">

			<ng-template [ngIf]="options?.showRecipientOptions" >
				<p class="u-text-bold u-margin-bottom3x">{{ 'shareBadgeDialog.badgeOptions' | translate }}:</p>
				<label class="checkbox checkbox-light u-margin-bottom5x">
					<input
						[(ngModel)]="includeRecipientIdentifier"
						(ngModelChange)="updateEmbedHtml()"
						type="checkbox"
						checked>
					<span class="checkbox-x-text">
              <strong>{{ 'shareBadgeDialog.includeRecipientIdentifier' | translate }}:</strong> <br>
              {{ options.recipientIdentifier }}
            </span>
				</label>
			</ng-template>

			<div class="forminput forminput-hasiconleft forminput-withbutton u-margin-bottom3x" *ngIf="options">
				<label class="forminput-x-label" for="link-input">{{ 'shareBadgeDialog.copyThisLinkToShare' | translate }}</label>
				<div class="forminput-x-inputs">
					<svg class="forminput-x-icon" icon="icon_link"></svg>

					<input
						id="link-input"
						name="link-input"
						type="text"
						[value]="currentShareUrl"
						(click)="$event.target.select()"
						readonly
						#urlInput>

					<div class="forminput-x-button">
						<button
							(click)="copyToClipboard(urlInput)"
							[hidden]="! copySupported"
							class="button button-secondary button-informinput">
							{{ 'shareBadgeDialog.copy' | translate }}
						</button>
					</div>

				</div>
			</div>

			<a
				*ngIf="options"
				[href]="currentShareUrl"
				target="_blank"
				class="u-text-link-small">{{ 'shareBadgeDialog.openInNewWindow' | translate }}</a>

		</div>

		<!-- Social Tab -->
		<div class="u-padding-yaxis2x u-responsivepadding-xaxis"
				tabindex="-1"
				id="sharelinksocial"
				*ngIf="currentTabId === 'social'"
		>
			<ng-template [ngIf]="options.showRecipientOptions">
				<p class="u-text-bold u-margin-bottom3x">{{ 'shareBadgeDialog.badgeOptions' | translate }}:</p>
				<label class="checkbox checkbox-light u-margin-bottom5x">
					<input
						id="form-checkbox"
						type="checkbox"
						[(ngModel)]="includeRecipientIdentifier"
						(ngModelChange)="updateEmbedHtml()"
						checked >
					<span class="checkbox-x-text">
						<strong>{{ 'shareBadgeDialog.includeRecipientIdentifier' | translate }}:</strong> <br>
						{{ options.recipientIdentifier }}
					</span>
				</label>
			</ng-template>

			<div class="l-grid l-grid-small">
				<button
					*ngIf="displayShareServiceType('Facebook')"
					type="button"
					(click)="openShareWindow($event, 'Facebook')"
					class="socialbutton socialbutton-facebook">facebook</button>
				<button
					*ngIf="displayShareServiceType('LinkedIn')"
					type="button"
					(click)="openShareWindow($event, 'LinkedIn')"
					class="socialbutton socialbutton-linkedin_oauth2">linked in</button>
				<button
					*ngIf="displayShareServiceType('Twitter')"
					type="button"
					(click)="openShareWindow($event, 'Twitter')"
					class="socialbutton socialbutton-twitter">twitter</button>
				<button
					*ngIf="displayShareServiceType('Pinterest')"
					type="button"
					(click)="openShareWindow($event, 'Pinterest')"
					class="socialbutton socialbutton-pinterest">pinterest</button>
			</div>
			
		</div>

		<!-- Embed Tab -->
		<div class="u-padding-yaxis2x u-responsivepadding-xaxis"
				tabindex="-1"
				id="sharelinkembed"
				*ngIf="currentTabId === 'embed'"
		>
			<div class="l-flex l-flex-1x u-margin-bottom3x"
					*ngIf="options.embedOptions.length > 1"
			>
				<ng-template ngFor let-embedOption [ngForOf]="options.embedOptions" let-i="index">
					<label class="radio" [attr.for]="'embed-type-' + i">
						<input
							type="radio"
							[name]="'embed-type-' + i"
							[id]="'embed-type-' + i"
							[value]="embedOption"
							[(ngModel)]="selectedEmbedOption"
							(ngModelChange)="updateEmbedHtml()"/>
						<span class="radio-x-text">{{ embedOption.label }}</span>
					</label>
				</ng-template>
			</div>

			<ng-template [ngIf]="options?.showRecipientOptions">
				<p class="u-text-bold u-margin-bottom2x">{{ 'shareBadgeDialog.includedBadgeOptions' | translate }}</p>

				<label class="checkbox checkbox-light u-margin-bottom2x">
					<input
						[(ngModel)]="includeRecipientIdentifier"
						(ngModelChange)="updateEmbedHtml()"
						type="checkbox"
						checked>
					<span class="checkbox-x-text">
              <strong>{{ 'shareBadgeDialog.recipientIdentifier' | translate }}:</strong> <br>
						{{ options.recipientIdentifier }}
            </span>
				</label>

				<label
					*ngIf="selectedEmbedOption && selectedEmbedOption?.embedType == 'image'"
					class="checkbox u-margin-bottom2x">
					<input
						[(ngModel)]="includeBadgeClassName"
						(ngModelChange)="updateEmbedHtml()"
						type="checkbox"
						checked>
					<span class="checkbox-x-text">
              {{ 'shareBadgeDialog.badgeName' | translate }}
            </span>
				</label>

				<label
					*ngIf="selectedEmbedOption && selectedEmbedOption?.embedType == 'image'"
					class="checkbox u-margin-bottom2x">
					<input
						[(ngModel)]="includeAwardDate"
						(change)="updateEmbedHtml()"
						type="checkbox"
						checked>
					<span class="checkbox-x-text">
              {{ 'shareBadgeDialog.dateAwarded' | translate }}
            </span>
				</label>

				<label
					*ngIf="selectedEmbedOption && selectedEmbedOption?.embedType == 'image'"
					class="checkbox u-margin-bottom2x">
					<input
						[(ngModel)]="includeVerifyButton"
						(change)="updateEmbedHtml()"
						type="checkbox"
						checked>
					<span class="checkbox-x-text">
              {{ 'shareBadgeDialog.verification' | translate }}
            </span>
				</label>

			</ng-template>

			<p class="u-text-small-bold u-margin-bottom0p5x u-margin-top2x">{{ 'shareBadgeDialog.preview' | translate }}</p>

			<iframe src="about:blank"
							class="previewIframe"
							style="width: 100%"></iframe>

			<div class="forminput forminput-code u-margin-top3x u-margin-bottom2x">
				<label class="forminput-x-label" for="emebed-code-box">{{ 'shareBadgeDialog.embededCode' | translate }}</label>
				<div class="forminput-x-inputs">
					<textarea
						id="emebed-code-box"
						name="emebed-code-box"
						readonly
						[value]="currentEmbedHtml"
						(click)="$event.target.select()"
						#embedHtmlInput>The embed code goes here and changes dynamically as the checks and radios are pressed above.</textarea>
				</div>
			</div>
			<div class="l-stack l-stack-right">
				<button
					type="button"
					[hidden]="! copySupported"
					(click)="copyToClipboard(embedHtmlInput)"
					class="button button-secondary">{{ 'shareBadgeDialog.copy' | translate }}</button>
			</div>
		</div>

	</div>
</dialog>
