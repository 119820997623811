
		<a [routerLink]="['/issuer/issuers/', badge?.issuerSlug || '', 'badges', badge?.slug || '']"
		   *ngIf="link && !loading && !failed">
			<img [src]="badgeImageUrl"
			     [title]="badge?.name"
			     [width]="size"
			     [height]="size"
			/>
		</a>
		<a *ngIf="!(link && !loading && !failed)">
			<img [src]="loadingBadgeUrl"
			     *ngIf="loading"
			     title="Loading Badge..."
			     [width]="size"
			     [height]="size"
			/>
			<img [src]="failedBadgeUrl"
			     *ngIf="! loading && failed"
			     title="Badge Failed to Load"
			     [width]="size"
			     [height]="size"
			/>
			<img [src]="badgeImageUrl"
			     *ngIf="! loading && ! failed"
			     [title]="badge?.name"
			     [width]="size"
			     [height]="size"
			/>
		</a>
		<img [src]="awardedIconActive ? greenCheckCircleUrl : grayCheckCircleUrl"
		     [width]="awardedIconSize"
		     [height]="awardedIconSize"
		     class="badge-x-awardedIcon"
		     *ngIf="awardedIconSize > 0" />
	