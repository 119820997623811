import {forwardRef, Inject, Injectable} from '@angular/core';
import {IssuerApiService} from './issuer-api.service';
import {Issuer} from '../models/issuer.model';
import {ApiIssuer, ApiIssuerForCreation, ApiIssuerForEditing, IssuerSlug} from '../models/issuer-api.model';
import {Observable} from 'rxjs';
import {StandaloneEntitySet} from '../../common/model/managed-entity-set';
import {CommonEntityManager} from '../../entity-manager/services/common-entity-manager.service';
import {first, map} from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class IssuerManager {
	issuersList = new StandaloneEntitySet<Issuer, ApiIssuer>(
		apiModel => new Issuer(this.commonEntityManager, this.translate),
		apiModel => apiModel.json.id,
		() => this.issuerApiService.listIssuers()
	);

	constructor(
		public issuerApiService: IssuerApiService,
		@Inject(forwardRef(() => CommonEntityManager))
		public commonEntityManager: CommonEntityManager,
		public translate : TranslateService
	) { }

	createIssuer(
		initialIssuer: ApiIssuerForCreation
	): Promise<Issuer> {
		return this.issuerApiService.createIssuer(initialIssuer)
			.then(newIssuer => this.issuersList.addOrUpdate(newIssuer));
	}

	get allIssuers$(): Observable<Issuer[]> {
		return this.issuersList.loaded$.pipe(map(l => l.entities));
	}

	editIssuer(
		issuerSlug: IssuerSlug,
		initialIssuer: ApiIssuerForEditing
	): Promise<Issuer> {
		return this.issuerApiService.editIssuer(issuerSlug, initialIssuer)
			.then(newIssuer => this.issuersList.addOrUpdate(newIssuer));
	}

	deleteIssuer(
		issuerSlug: IssuerSlug,
		issuerToDelete: Issuer
	): Promise<boolean> {
		return this.issuerApiService.deleteIssuer(issuerSlug)
			.then(response => this.issuersList.remove(issuerToDelete));
	}

	issuerBySlug(issuerSlug: IssuerSlug): Promise<Issuer> {
		return this.allIssuers$
			.pipe(first())
			.toPromise()
			.then(issuers =>
				issuers.find(i => i.slug === issuerSlug)
				|| this.throwError(`Issuer Slug '${issuerSlug}' not found`)
			);
	}

	issuerAnalyticsBySlug(issuerSlug: IssuerSlug) {
		return this.issuerApiService.getIssuerAnalytics(issuerSlug)
			.then(issuerAnalytics =>  issuerAnalytics);
	}

	badgeAnalyticsBySlugs(badgeSlugs: string[]) {
		return this.issuerApiService.getBadgesAnalytics(badgeSlugs)
			.then(badgeAnalytics =>  badgeAnalytics);
	}

	private throwError(message: string): never {
		throw new Error(message);
	}
}
