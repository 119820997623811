
		<span #anchor
		      (click)="toggleTip()"
		><ng-content select=".trigger"></ng-content></span>
		<div #tooltip
		     class="tooltip"
		     [ngClass]="{'tooltip-is-active':active}"
		>
			<span class="tooltip-x-arrow"></span>
			<div class="tooltip-x-content">
				<button type="button"
				        (click)="updateTip(false)"
				>Close
				</button>
				<ng-content select="header"></ng-content>
				<ng-content select="content"></ng-content>
				<ng-content select="footer"></ng-content>
			</div>
		</div>
	