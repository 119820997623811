
		<label class="forminput-x-label" [attr.for]="inputName" *ngIf="label || includeLabelAsWrapper">
			{{ label }}
			<span *ngIf="formFieldAside">{{ formFieldAside }}</span>
			<button type="button" *ngIf="isLockedState" (click)="unlock()">(unlock)</button>
			<ng-content select="[label-additions]"></ng-content>
		</label>

		<label class="visuallyhidden" [attr.for]="inputName" *ngIf="ariaLabel">{{ ariaLabel }}</label>

		<div class="forminput-x-sublabel" *ngIf="description">{{ description }}</div>
		<div class="forminput-x-inputs">
			<select
				[name]="inputName"
				[id]="inputName"
				[formControl]="control"
				(focus)="cacheControlState()"
				(keypress)="handleKeyPress($event)"
				#selectInput
			>
				<option *ngIf="placeholder" selected value="">{{ placeholder }}</option>
				<option *ngFor="let option of options" [value]="option.value">{{ option.label }}</option>
			</select>
		</div>

		<p class="forminput-x-error" *ngIf="isErrorState">{{ errorMessageForDisplay }}</p>
	