
	<div class="mdeditor">
		<div class="mdeditor-x-editor">
			<label [attr.for]="inputName" *ngIf="label || includeLabelAsWrapper">
				{{ label }} <span *ngIf="optional">(OPTIONAL)</span>
				<span *ngIf="formFieldAside">{{ formFieldAside }}</span>
				<button type="button" *ngIf="isLockedState" (click)="unlock()">(unlock)</button>
				<ng-content select="[label-additions]"></ng-content>
			</label>
				<textarea
				autosize
				[name]="inputName"
				[ngStyle]="{'height.px':textHeight}"
				[id]="inputName"
				[formControl]="control"
				[placeholder]="placeholder || ''"
				(change)="postProcessInput()"
				(focus)="cacheControlState()"
				(keypress)="handleKeyPress($event)"
				*ngIf="!_preview"
				#textareaInput
			></textarea>
		</div>

		<div class="markdown mdeditor-x-preview"
			     #markdownPreviewPane
			     [bgMarkdown]="control.value"
			     [style.minHeight.px]="textHeight"
			     *ngIf="_preview"
			>Markdown preview
			</div>

    <div class="mdeditor-x-tabbar">
		<div class="mdeditor-x-tabs">
			<div class="mdeditor-x-tab mdeditor-x-writebutton" [ngClass]="{'mdeditor-x-tab-is-active':!_preview}"
			(click)="markdownPreview(false);">{{ 'issueBadgePage.write' | translate }}
			</div>
			<div class="mdeditor-x-tab mdeditor-x-previewbutton" [ngClass]="{'mdeditor-x-tab-is-active':_preview}"
			(click)="markdownPreview(true);">{{ 'issueBadgePage.preview' | translate }}
			</div>
		</div>
		<div class="mdeditor-x-help">
			<div class="l-flex l-flex-1x l-flex-aligncenter">
				<button class="buttonicon buttonicon-link" type="button" (click)="openMarkdownHintsDialog()">
					<svg class="icon l-flex-shrink0" icon="icon_markdown"></svg>
				</button>
				<button (click)="openMarkdownHintsDialog()" type="button" class="u-text-link-small u-hidden-maxmobilelarge">{{ 'issueBadgePage.markdownSupported' | translate }}</button>
			</div>
		</div>
    </div>
</div>
