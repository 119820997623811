
	<div class="l-toast">
		<div *ngIf="msg" class="notification notification-toast {{notification.submodule}}" [class.notification-is-active]="message">
			<div class="notification-x-icon">
				<svg class="navitem-x-icon" [attr.icon]="notification.icon"></svg>
			</div>
			<div class="notification-x-text">
				<h2>{{ notification.title }}</h2>
				<p>{{ msg }}</p>
			</div>
			<button class="notification-x-close buttonicon buttonicon-clear" (click)="dismissMessage()">
				<svg class="navitem-x-icon" [attr.icon]="'icon_close'"></svg>
				<span class="visuallyhidden">Close Notification</span>
			</button>
		</div>
	</div>