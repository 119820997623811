
		<div #container
		     class="showmore-x-container"
		>
			<div #content class="showmore-x-content">
				<ng-content></ng-content>
			</div>
		</div>
		<button class="showmore-x-button" (click)="toggleOpen()">
			<div class="showmore-x-buttoninside">{{ isOpen ? showLess : showMore }}</div>
		</button>
	