
		<form *ngIf="toolLaunchInfo"
			#toolLaunchForm
			action="{{toolLaunchInfo.launch_url}}"
			method="POST"
			encType="application/x-www-form-urlencoded">
			<input
				*ngFor="let key of objectKeys(toolLaunchInfo.launch_data)"
				type="hidden"
				name="{{key}}"
				value="{{toolLaunchInfo.launch_data[key]}}"/>
		</form>
	