
		<p class="visuallyhidden">
			{{ label }}
			<ng-content select="[label-additions]"></ng-content>
		</p>
		<input type="file"
		       accept="{{validFileTypes}}"
		       name="{{ name }}"
		       id="{{ name }}"
			   (change)="fileInputChanged($event)"
			   class="visuallyhidden"
		/>
		<label [attr.for]="name" (click)="clearFileInput()" class="l-flex l-flex-column l-flex-aligncenter">
		<svg class="dropzone-x-icon" icon="icon_upload"></svg>
			<div class="dropzone-x-text" *ngIf="! fileErrorMessage">
				<div *ngIf="! fileProvided && ! fileLoading" class="u-text-link">{{ 'issueBadgeBulkImportPage.dropFileOrBrowse' | translate }}.</div>
				<div *ngIf="fileLoading" class="dropzone-x-info1">{{ 'issueBadgeBulkImportPage.loadingFile' | translate }}...</div>
				<div *ngIf="fileName" class="dropzone-x-info1">{{ fileName }}</div>
				<div *ngIf="fileName" class="u-text-link">{{ 'issueBadgeBulkImportPage.change' | translate }}</div>
			</div>

			<div *ngIf="fileErrorMessage" class="dropzone-x-error">{{ fileErrorMessage }}</div>
			<!--</span>-->
		</label>
		<p class="dropzone-x-error" *ngIf="control?.dirty && !control?.valid">{{ errorMessage }}</p>
	