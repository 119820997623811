
      <a [routerLink]="['/issuer/issuers/', badge?.issuerSlug||'', 'badges', badge?.slug||'']"
         class="card-x-main"
      >
        <div class="card-x-image">
          <badge-image [badge]="badge" [size]="40" [forceFailed]="failed"></badge-image>
        </div>
        <div class="card-x-text">
          <h1>{{ loading ? "Loading Badge..." : failed ? "Unknown Badge" : badge.name }}</h1>
          <small [truncatedText]="badge ? badge.description : ''" [maxLength]="100"></small>
        </div>
      </a>
      <div class="card-x-actions">
        <button class="button button-secondaryghost l-offsetright l-offsetbottom"
                (click)="removeConnection()">Remove</button>
      </div>
    