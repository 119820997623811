<ng-template sourceListener></ng-template>
<header class="o-container">
	<nav class="navbar l-containerxaxis l-stickyfooter-x-nav" [class.navbar-light]="!theme.useColorNavbar">

		<a [class.logo-is-loading]="isRequestPending" [href]="isOAuthAuthorizationInProcess ? '#' : theme.alternateLandingUrl || '/'">
			<picture class="navbar-x-logo">
				<source media="(min-width: 768px)" [srcset]="logoDesktop">
				<img [src]="logoSmall" alt="Logo">
			</picture>
		</a>

		<!-- Unauthenticated -->
		<ng-template [ngIf]="!loggedIn">
			<nav class="navbar-x-items">
				<div class="navbar-x-navitems">
					<a [class.navitem-light]="!theme.useColorNavbar" class="navitem" routerLinkActive="navitem-is-active" [routerLink]="['/auth/login']">
					{{ 'menuItems.signIn' | translate }}</a>
					<a *ngIf="!features.disableRegistration" [class.navitem-light]="!theme.useColorNavbar" class="navitem"
						 routerLinkActive="navitem-is-active" [routerLink]="['/signup']">{{ 'menuItems.createAccount' | translate }}</a>
				</div>
			</nav>
		</ng-template>

		<!-- Authenticated -->
		<ng-template [ngIf]="loggedIn && ! isOAuthAuthorizationInProcess">
			<nav class="navbar-x-items" role="menubar">
				<div class="navbar-x-navitems">
					<a role="menuitem" [class.navitem-light]="!theme.useColorNavbar" class="navitem" routerLinkActive="navitem-is-active"
					 [routerLink]="['/recipient/badges']">{{ 'menuItems.backpack' | translate }}</a>
					<a role="menuitem" [class.navitem-light]="!theme.useColorNavbar" class="navitem" routerLinkActive="navitem-is-active"
					 [routerLink]="['/recipient/badge-collections']">{{ 'menuItems.collections' | translate }}</a>
					
					<a role="menuitem" [class.navitem-light]="!theme.useColorNavbar"
					 class="navitem" routerLinkActive="navitem-is-active" [routerLink]="['/issuer']">{{ 'menuItems.issuers' | translate }}</a>
					
					 <!-- <a role="menuitem" *ngIf="showIssuersTab" [class.navitem-light]="!theme.useColorNavbar"
					 class="navitem" routerLinkActive="navitem-is-active" [routerLink]="['/issuer']">Issuers</a> -->

					<button role="menuitem" aria-haspopup="true" [class.navitem-light]="!theme.useColorNavbar" class="navitem u-cursor-pointer"
					 *ngIf="launchpoints?.length" routerLinkActive="navitem-is-active" [bgPopupMenuTrigger]="appsMenu">
						Apps
						<svg class="navitem-x-icon" icon="icon_dropdown"></svg>
					</button>
					<a role="menuitem" [class.navitem-light]="!theme.useColorNavbar" class="navitem u-cursor-pointer" *ngIf="theme.customMenu"
					 routerLinkActive="navitem-is-active" [bgPopupMenuTrigger]="customMenu">
						{{ theme.customMenu.label }}
						<svg class="navitem-x-icon" icon="icon_dropdown"></svg>
					</a>
					<button role="menuitem" aria-haspopup="true" [class.navitem-light]="!theme.useColorNavbar" class="navitem u-cursor-pointer"
					 routerLinkActive="navitem-is-active" [bgPopupMenuTrigger]="accountMenu">
						{{ 'menuItems.account' | translate }}
						<svg class="navitem-x-icon" icon="icon_dropdown"></svg>
					</button>
				</div>
			</nav>
		</ng-template>
		<button class="menutoggle" (click)="toggleMobileNav()" [class.menutoggle-is-active]="mobileNavOpen" [class.menutoggle-light]="!theme.useColorNavbar">
			<span></span>
			<span></span>
			<span></span>
			<i class="visuallyhidden">Open Menu</i>
		</button>
	</nav>

	<!-- Nav dropdown menus -->

	<bg-popup-menu #appsMenu>
		<a class="menuitem" href="{{lp.launch_url}}" *ngFor="let lp of launchpoints" target="_blank">
			{{lp.label}}
		</a>
	</bg-popup-menu>

	<bg-popup-menu #customMenu>
		<a class="menuitem" [href]="item.url" *ngFor="let item of theme?.customMenu?.items" target="_blank">
			{{ item.label }}
		</a>
	</bg-popup-menu>

	<bg-popup-menu #accountMenu>
		<a class="menuitem" routerLinkActive="menuitem-is-active" [routerLink]="['/profile/profile']"><svg icon="icon_member"></svg>{{ 'menuItems.profile' | translate }}</a>
		<a class="menuitem" routerLinkActive="menuitem-is-active" [routerLink]="['/profile/app-integrations']"><svg icon="icon_compare"></svg>
			{{ 'menuItems.appIntegrations' | translate }}</a>
		<a class="menuitem" routerLinkActive="menuitem-is-active" [routerLink]="['/auth/logout']"><svg icon="icon_exit_to_app"></svg>
			{{ 'menuItems.signOut' | translate }}</a>
	</bg-popup-menu>


	<nav class="l-dialog mobilenav" [class.mobilenav-is-active]="mobileNavOpen" *ngIf="!loggedIn" [class.mobilenav-light]="!theme.useColorNavbar">
		<div class="mobilenav-x-navitems">
			<a routerLinkActive="menuitem-is-active" [routerLink]="['/auth/login']">{{ 'menuItems.signIn' | translate }}</a>
			<a routerLinkActive="menuitem-is-active" [routerLink]="['/signup']">{{ 'menuItems.createAccount' | translate }}</a>
			<hr class="mobilenav-x-rule">
			<p class="mobilenav-x-label" *ngIf="launchpoints?.length">Apps</p>
			<a href="{{lp.launch_url}}" *ngFor="let lp of launchpoints" target="_blank">
				{{lp.label}}
			</a>
		</div>
	</nav>

	<nav class="l-dialog mobilenav" [class.mobilenav-is-active]="mobileNavOpen" *ngIf="loggedIn && ! isOAuthAuthorizationInProcess"
	 [class.mobilenav-light]="!theme.useColorNavbar">
		<div class="mobilenav-x-navitems">
			<a [routerLink]="['/recipient/badges']">{{ 'menuItems.backpack' | translate }}</a>
			<a [routerLink]="['/recipient/badge-collections']">{{ 'menuItems.collections' | translate }}</a>
			<!-- <a [routerLink]="['/issuer']" *ngIf="showIssuersTab">Issuers</a> -->

			<hr class="mobilenav-x-rule" *ngIf="launchpoints?.length">
			<p class="mobilenav-x-label" *ngIf="launchpoints?.length">Apps</p>
			<a href="{{lp.launch_url}}" *ngFor="let lp of launchpoints" target="_blank"> {{lp.label}} </a>

			<hr class="mobilenav-x-rule" *ngIf="theme.customMenu">
			<p class="mobilenav-x-label" *ngIf="theme.customMenu">{{ theme.customMenu.label }}</p>
			<a [href]="item.url" *ngFor="let item of theme?.customMenu?.items" target="_blank"> {{ item.label }} </a>

			<hr class="mobilenav-x-rule">
			<p class="mobilenav-x-label">{{ 'menuItems.account' | translate }}</p>
			<a [routerLink]="['/profile/profile']">{{ 'menuItems.profile' | translate }}</a>
			<a [routerLink]="['/profile/app-integrations']">{{ 'menuItems.appIntegrations' | translate }}</a>
			<a [routerLink]="['/auth/logout']">{{ 'menuItems.signOut' | translate }}</a>
		</div>
	</nav>


</header>
<div class="loader l-stickyfooter-x-loader"></div>

<main class="l-stickyfooter-x-main o-container" [attr.inert]="mobileNavOpen ? '' : null" [class.l-inertcontainer]="mobileNavOpen">
	<router-outlet *ngIf="!hasFatalError"></router-outlet>
	<!-- children go here -->
	<article *ngIf="hasFatalError" class="l-containerxaxis l-containeryaxis">
		<div class="l-flex l-flex-justifycenter">
			<div>
				<h1 *ngIf="fatalMessage" class="u-text-h2-bold u-text-center">{{fatalMessage}}</h1>
				<h1 *ngIf="fatalMessageDetail" class="u-text-h3 u-text-center">{{fatalMessageDetail}}</h1>
				<h1 *ngIf="!fatalMessage" class="u-text-body-bold u-text-center">Whoops! The server has failed to respond.</h1>
				<h1 *ngIf="!fatalMessageDetail" class="u-text-body u-text-center">Please refresh and try again.</h1>
				<img class="u-width-form" [src]="unavailableImageSrc">
			</div>
		</div>
	</article>
</main>

<!-- TODO: Assess dialog patterns - use inert polyfill -->
<confirm-dialog #confirmDialog></confirm-dialog>
<new-terms-dialog #newTermsDialog></new-terms-dialog>
<share-social-dialog #shareSocialDialog></share-social-dialog>
<markdown-hints-dialog #markdownHintsDialog></markdown-hints-dialog>

<!-- Footer -->
<footer class="o-container">
	<div class="l-containerxaxis">
		<div class="footer l-stickyfooter-x-footer">

			<ul class="footer-x-leftlinks">
				<li><img src="https://dbadge.com.br/wp-content/uploads/2020/06/dbadge-logo.png" alt="d-badge" style="width:183px;height:47px;"></li>
				<li *ngIf="theme.showPoweredByBadgr === undefined || theme.showPoweredByBadgr">Powered by <a href="https://dbadge.com.br">d-badge</a></li>
				<li *ngIf="theme.providedBy">Provided by <a href="{{ theme.providedBy.url}}" target="_blank">{{
						theme.providedBy.name }}</a></li>
			</ul>

			<ul class="footer-x-links">
				<li>	
					<span>{{ 'footerItems.chooseLanguage' | translate }} </span>
						<div class="forminput languageSelectField">
							<div class="forminput-x-inputs languageSelectField">
								<select
									class="languageSelectField u-margin-left2x"
									name="languageSelect"
									id="languageSelect"
									(change)="selectLanguage($event.target.value)"
								>
									<option selected value="none">{{ 'Select a Language' }}</option>
									<option *ngFor="let option of translationOptions" [value]="option.value">{{ option.label }}</option>
								</select>
							</div>
						</div>
				</li>
				<li><a [href]="theme.termsOfServiceLink || 'https://dbadge.com.br/terms'" target="_blank">{{ 'footerItems.termsOfService' | translate }}</a></li>
				<li><a [href]="theme.privacyPolicyLink || 'https://dbadge.com.br/privacy-policy'" target="_blank">{{ 'footerItems.privacyPolicy' | translate }}</a></li>
				<li *ngIf="theme.showApiDocsLink === undefined || theme.showApiDocsLink"><a href="https://dbadge.com.br"
					 target="_blank">{{ 'footerItems.aboutUs' | translate }}</a></li>
				<li>{{ 'footerItems.madeWithLove' | translate }}</li>
			</ul>

		</div>
	</div>
</footer>
